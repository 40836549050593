<template>
  <div class="relative z-30" role="dialog" aria-modal="true">
    <Transition name="tr-fade" appear @after-leave="emit('dismiss')">
      <div
        v-if="active"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
      />
    </Transition>

    <div class="fixed inset-0 z-10 overflow-y-auto">
      <div
        class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
      >
        <slot :active="active" :dismiss="dismiss" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  (e: 'dismiss'): void;
}>();

const active = ref(true);
const dismiss = () => {
  active.value = false;
};

onKeyStroke('Escape', () => {
  dismiss();
});

const route = useRoute();
watch(() => route.fullPath, dismiss);
</script>
